import { Instance, SnapshotOut, types, flow, SnapshotIn } from "mobx-state-tree"
import { AuthApi } from "../../services/api/auth-api"
import { withEnvironment } from "../extensions/with-environment"
import { withRootStore } from "../extensions/with-root-store"
import { IPlaceOfBirth, PlaceOfBirthModel } from "../profile-store/place-of-birth-model"

export enum LoginSocialTypeEnum {
  Default = "default",
  Google = "google",
  Apple = "apple",
}

export const WelcomeStoreModel = types
  .model("WelcomeStore")
  .props({
    userName: types.optional(types.string, ""),
    confirmationCode: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),
    gender: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    dateOfBirth: types.optional(types.string, ""),
    timeOfBirth: types.optional(types.string, ""),
    placeOfBirth: types.maybeNull(PlaceOfBirthModel),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    sendConfirmation: flow(function* (userName: string) {
      const authApi = new AuthApi(self.environment.api)
      const result = yield authApi.sendConfirmation(userName)

      if (result.kind === "ok") {
        self.userName = userName
        self.email = userName
        return true
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    verifyConfirmation: flow(function* () {
      const authApi = new AuthApi(self.environment.api)
      const result = yield authApi.verifyConfirmation(self.userName, self.confirmationCode)

      if (result.kind === "ok") {
        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    login: flow(function* (confirmationCode: string) {
      const authApi = new AuthApi(self.environment.api)
      const result = yield authApi.login(self.userName, confirmationCode)
      if (result.kind === "ok") {
        self.confirmationCode = confirmationCode
        const token = result.customer.data.token
        const refreshToken = result.customer.data.refreshToken
        self.rootStore.meStore.setTokens(token, refreshToken)
        self.rootStore.meStore.setAuthenticated(true)
        return result
      } else if (result?.kind === "server") {
        const message: string = result.message
        return { message }
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    loginSocial: flow(function* (token: string, loginType: string) {
      const authApi = new AuthApi(self.environment.api)
      let result = null
      if (loginType === LoginSocialTypeEnum.Google) {
        result = yield authApi.loginGoogle(token)
      } else if (loginType === LoginSocialTypeEnum.Apple) {
        result = yield authApi.loginApple(token)
      }

      if (result?.kind === "ok") {
        const isNewUser: boolean = result.customer.data.isNew
        if (isNewUser) {
          self.firstName = result.customer.data.userData.firstName
          self.lastName = result.customer.data.userData.lastName
          self.email = result.customer.data.userData.email
          self.confirmationCode = result.customer.data.userData.confirmationCode
        } else {
          const token = result.customer.data.login.token
          const refreshToken = result.customer.data.login.refreshToken
          self.rootStore.meStore.setTokens(token, refreshToken)
          self.rootStore.meStore.setAuthenticated(true)
        }
        return { isNewUser }
      } else if (result?.kind === "server") {
        const message: string = result.message
        return { message }
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    registration: flow(function* () {
      const authApi = new AuthApi(self.environment.api)
      const result = yield authApi.registration(
        self.firstName,
        self.lastName,
        self.gender,
        self.email,
        self.dateOfBirth,
        self.timeOfBirth,
        self.placeOfBirth,
        self.confirmationCode,
        "en", // Todo app lang
      )

      if (result.kind === "ok") {
        const token = result.customer.data.token
        const refreshToken = result.customer.data.refreshToken
        self.rootStore.meStore.setTokens(token, refreshToken)
        self.rootStore.meStore.setAuthenticated(true)

        return result
      } else {
        __DEV__ && console.tron.log(result.kind)
        return false
      }
    }),
    loginGuest: () => {
      self.rootStore.meStore.setShouldLogOutGuest(false)
      self.rootStore.meStore.setGuest(true)
      self.rootStore.meStore.setAuthenticated(true)

      self.rootStore.profileStore.clearProfile()
      self.rootStore.profileStore.updateName("Guest", "Guest")
      self.rootStore.profileStore.updateBirthName("Guest", "Guest")
    },
  }))
  .actions((self) => ({
    step2: (code: string) => {
      self.confirmationCode = code
    },
    step3: (firstName: string, lastName: string, gender: string) => {
      self.firstName = firstName
      self.lastName = lastName
      self.gender = gender
    },
    step4: (date: string, time: string) => {
      self.dateOfBirth = date
      self.timeOfBirth = time
    },
    step5: (placeOfBirth: IPlaceOfBirth) => {
      self.placeOfBirth = placeOfBirth?.name
        ? {
            geonameId: placeOfBirth?.geonameId,
            toponymName: placeOfBirth?.toponymName,
            name: placeOfBirth?.name,
            displayName: placeOfBirth?.displayName,
          }
        : null
    },
    clearSteps: () => {
      self.userName = ""
      self.confirmationCode = ""
      self.firstName = ""
      self.lastName = ""
      self.gender = ""
      self.email = ""
      self.dateOfBirth = ""
      self.timeOfBirth = ""
      self.placeOfBirth = null
    },
  }))

export interface WelcomeStore extends Instance<typeof WelcomeStoreModel> {}
export interface WelcomeStoreSnapshotOut extends SnapshotOut<typeof WelcomeStoreModel> {}
export interface WelcomeStoreSnapshotIn extends SnapshotIn<typeof WelcomeStoreModel> {}
export const createWelcomeStoreDefaultModel = () => types.optional(WelcomeStoreModel, {})
