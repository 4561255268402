import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { AuthResult, CommonResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ApiRoutes } from "./api-config"
import { IPlaceOfBirth } from "../../models/profile-store/place-of-birth-model"

export interface IFullNameData {
  givenName: string
  namePrefix: string
  nameSuffix: string
  nickname: string
  familyName: string
}
export interface IAppleAuthParams {
  identityToken: string
  realUserStatus: number
  authorizationCode: string
  user: string
  fullName: IFullNameData
  email: string
}
export class AuthApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async login(userName: string, confirmationCode: string): Promise<AuthResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.AUTH_LOGIN, {
        userName,
        confirmationCode,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async loginGoogle(token: string): Promise<AuthResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.AUTH_GOOGLE, {
        token,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async loginApple(token: string): Promise<AuthResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.AUTH_APPLE, {
        token,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      const customer = response.data
      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async registration(
    firstName: string,
    lastName: string,
    gender: string,
    email: string,
    dateOfBirth: string,
    timeOfBirth: string,
    placeOfBirth: IPlaceOfBirth,
    confirmationCode: string,
    preferredLocale: string,
  ): Promise<AuthResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.AUTH_REGISTER, {
        firstName,
        lastName,
        gender,
        email,
        dateOfBirth,
        timeOfBirth,
        placeOfBirth,
        confirmationCode,
        preferredLocale,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async sendConfirmation(userName: string): Promise<AuthResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        ApiRoutes.AUTH_SEND_CONFIRMATION,
        {
          userName,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data.results

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async verifyConfirmation(userName: string, code: string): Promise<CommonResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        ApiRoutes.AUTH_VERIFY_CONFIRMATION,
        {
          userName,
          code,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data

      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async refreshToken(refreshToken: string): Promise<AuthResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        ApiRoutes.AUTH_REFRESH_TOKEN,
        {
          token: refreshToken,
        },
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async logOut(refreshToken: string): Promise<AuthResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(ApiRoutes.AUTH_REVOKE_TOKEN, {
        token: refreshToken,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const customer = response.data

      return { kind: "ok", customer }
    } catch (e) {
      __DEV__ && console.tron.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
